











































































































































































// Core
import { Component, Prop, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

// Mixins
import GetStoreMixin from '@/mixins/GetStore'

// Store
import { documentsNamespace } from '@store/documents'

// Types
import { TaskActions } from '@store/task/types'

// Interfaces
import { ITask } from '@interfaces/Interface'
import { IDocument, IDocumentTypes } from '@store/documents/Interface'
import { Team, TeamState } from '@store/team/interfaces'

// Modules
const NSTeam = namespace('teamModule')

interface IFormChange {
  projectId: number
  name: string
  category: number | null
  description: string
  assigneeId: number | null
  fileList: any[]
  documentIds: number | number[],
  downloadDocumentIds: number[],
}
const NSTask = namespace('taskModule')

@Component({
  name: 'FormChange',

  components: {
    'v-dialog-choose-file': () => import('@/components/dialogs/DialogChooseFile.vue'),
  },
})
export default class FormChangeComponent extends Mixins(GetStoreMixin) {
  @Prop({ default: () => null })
  private documentation!: IDocument

  @Prop({ default: () => [] })
  private docIds!: number[]

  @Prop({ default: true })
  private showCategory!: boolean

  @Prop({ default: false })
  private disabledCategory!: boolean

  @Prop({ default: 'Изменить' })
  private buttonSendName!: IDocument

  @Prop({ default: null })
  private task!: ITask

  @Prop({ default: null })
  private category!: number

  @documentsNamespace.State('documentTypes')
  private documentType!: IDocumentTypes

  @NSTeam.State((state: TeamState) => state.team.data)
  private team!: Team[]

  @NSTask.Action(TaskActions.A_CREATE_TASK)
  private createTask!: (formChange: IFormChange) => Promise<any>

  private isExpectation: boolean = false

  private visibleDialogChooseFile = false

  protected rules: any = {
    name: [{ required: true, message: 'Обязательное поле', trigger: 'blur' }],
    category: [{ required: true, message: 'Обязательное поле', trigger: 'blur' }],
    description: [{ required: true, message: 'Обязательное поле', trigger: 'blur' }],
    assigneeId: [{ required: true, message: 'Обязательное поле', trigger: 'change' }],
  }

  private formChange: IFormChange = {
    projectId: this.selectedProject,
    documentIds: this.documentation?.id ? [ this.documentation.id ] : this.docIds,
    name: '',
    category: null,
    description: '',
    assigneeId: null,
    fileList: [],
    downloadDocumentIds: [],
  }

  get files() {
    return this.formChange.fileList.map(file => file.file)
  }

  private changeFileList(_file, fileList) {
    this.formChange.fileList = fileList.map(item => ({file: item}))
  }

  private change() {
    const ref: any = this.$refs.formChange
    ref.validate(valid => {
      if (valid) {
        this.isExpectation = true
        this.createTask(this.formChange)
          .then(() => {
            this.isExpectation = false
            this.$emit('close-dialog')
            this.$notify({
              title: 'Выполнено',
              message: 'Действие успешно выполнено',
              type: 'success',
            })
          })
          .catch(error => {
            this.isExpectation = false
            this.$notify.error({
              title: 'Ошибка',
              message: error.response.data.message,
            })
          })
      } else {
        return false
      }
    })
  }

  private deleteDocument(uid) {
    const index = this.formChange.fileList.findIndex(file => file.file.uid === uid)
    this.formChange.fileList.splice(index, 1)
  }

  protected mounted() {
    this.formChange.projectId = this.selectedProject

    if (this.category) {
      this.formChange.category = this.category
    }
    if (this.documentation !== null) {
      this.formChange.name = this.documentation.filename
      this.formChange.category = this.documentation.typeId
    }
  }

  private handleChooseFileFromLibrary (fileIds) {
    this.formChange.downloadDocumentIds = fileIds
  }
}
